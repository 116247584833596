<template>
    <div class="loader"></div>
</template>
<script>
  export default {
    name: "Circular",
    mounted() {
      // alert('99')
    }
  }
</script>
<style lang="scss" scoped>
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat, conic-gradient(#0000 30%,#ffa516);
    //background: radial-gradient(farthest-side, #ac1616 94%, transparent) top / 8px 8px no-repeat, conic-gradient(transparent 30%, #9f1a1a);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation: l13 1s infinite linear;
  }
  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }
</style>